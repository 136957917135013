<template>
    <div class="AwardPool">
        <a-modal visible=''
                 title="优惠卷列表"
                 centered
                 okText="确定"
                 cancelText="取消"
                 width="900px"
                 height="500px"
                 @ok="onOk"
                 @cancel="cancel">
            <a-row style="margin-bottom:15px;">
                <a-col :span="5">
                    <a-input placeholder="优惠券名称"
                             v-model="Name"></a-input>
                </a-col>
                <a-col :span="5" style="margin-left: 10px">
                    <a-button type="primary" value="查询" @click="name_change">搜索</a-button>
                </a-col>
            </a-row>
            <div style="height:400px;overflow:auto;">
                <a-table :columns="columns"
                         rowKey="ID"
                         :data-source="tableData"
                         :pagination="pagination"
                         @change="pagerChange"
                         class="yj-table-img"
                         :rowSelection="{selectedRowKeys: IDS,onChange: onSelectChange,getCheckboxProps:getCheckboxProps, type:SelectType}"
                         :customRow="onClickRowMulti">
                    <span slot="img" slot-scope="text, record">
                        <img :src="Getsrc(record)" style="object-fit:cover;" width="148" />
                    </span>
                </a-table>
            </div>
        </a-modal>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    export default {
        name: "",
        data() {
            return {
                Name: "",
                tableData: [],
                IDS: [],
                columns: [
                    {
                        title: "图片",
                        width: 200,
                        scopedSlots: { customRender: 'img' }
                    },
                    {
                        title: "名称",
                        width: 200,
                        dataIndex: "Name"
                    }
                ],
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
            };
        },
        props: {//组件属性
            hide: {
                type: Function,
                default: null,
            },
            IDList: {
                type: Array,
                default: () => []
            },
            SelectType: String,
            ID: Number,
        },
        methods: {//方法
            onOk: function () {
                var self = this;
                var keyArray = new Array();
                self.IDS.forEach((e) => {
                    self.tableData.forEach((a) => {
                        if (e == a.ID) {
                            keyArray.push({
                                AwardPoolID: a.ID,
                                Name: a.Name,
                                PrizeType: a.PrizeType,
                                Pic: a.Extend.Pic,
                            });
                        }
                    });
                });
                self.hide(1, keyArray);
            },
            name_change: function () {
                var self = this;
                self.pagination.current = 1;
                self.GetDataList();
            },
            GetDataList: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/M2Activity/GetAwardPoolList",
                    data: {
                        Name: self.Name,
                        PageIndex: self.pagination.current,
                        EndTime: new Date(),
                        PubState: 1,
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    },
                };
                http.Post(op);
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.GetDataList();
            },
            onSelectChange(e) {
                var self = this;
                self.IDS = e;
            },
            onClickRowMulti(record) {
                return {
                    on: {
                        click: () => {
                            var self = this;
                            if (self.SelectType == "radio") {
                                self.IDS = [];
                                self.IDS.push(record.ID)
                            } else {
                                let rowKeys = self.IDS
                                if (rowKeys.length > 0 && rowKeys.includes(record.ID)) {
                                    rowKeys.splice(rowKeys.indexOf(record.ID), 1)
                                } else {
                                    rowKeys.push(record.ID)
                                }
                                self.IDS = rowKeys;
                            }
                        }
                    }
                }
            },
            Getsrc: function (e) {
                var src = "/img/nourl.b1240360.png";
                if (e.Extend.Pic != "") {
                    src = e.Extend.Pic;
                }
                return src;
            },
            loadData() {
                var self = this;
                self.IDS = [];
                if (self.SelectType == "radio") {
                    self.IDS.push(self.ID)
                } else {
                    self.IDList.forEach((a) => {
                        self.IDS.push(a.AwardPoolID);
                    });
                }
                self.pagination.current = 1;
                self.GetDataList();
            },
            cancel: function () {
                var self = this;
                self.hide(0, new Array());
            },
            getCheckboxProps() {
                return record => ({
                    props: {
                        defaultChecked: this.IDS.includes(record.ID)
                    }
                })
            }
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.loadData();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>
<style>
    .AwardPool {
        position: absolute;
        z-index: 99999;
        width: 100%;
        left: 0;
    }
</style>